<template>
  <div>
    <b-card title="Add Specify">
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col sm="12">
                <div>
                  <!-- Row Loop -->

                  <b-row>
                    <!-- Taxonomy Reapeted -->

                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Locale"
                        rules="required"
                      >
                        <b-form-group
                          label="Locale"
                          label-for="active"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            :id="'locale'"
                            disabled
                            v-model="tranLocaleSelect"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="localeOptions"
                            label="text"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Term"
                        rules="required"
                      >
                        <b-form-group
                          label="Term"
                          label-for="term"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            :id="'term'"
                            v-model="termsSelect"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="terms"
                            label="text"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Name" label-for="name">
                        <validation-provider
                          #default="{ errors }"
                          name="taxonomy name"
                          rules="required"
                        >
                          <b-form-input
                            :id="'name'"
                            v-model="data.name"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Description" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="taxonomy description"
                          rules="required"
                        >
                          <b-form-input
                            :id="'texonomyDescription'"
                            v-model="data.description"
                            :state="errors.length > 0 ? false : null"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <hr />
          </b-col>

          <!-- submit button -->

          <b-col>
            <b-button
              size="lg"
              variant="primary"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

    <!-- error handelr -->

    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>

      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'
import BCardCode from '@core/components/b-card-code'
import { required } from '@validations'
export default {
  components: {
    BCardCode,
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      tranLocaleSelect: {
        text: '',
        value: '',
      },
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      termsSelect: null,
      terms: [],
      data: {
        term_id: '',
        name: '',
        description: '',
        locale: '',
      },
      required,
    }
  },
  created() {
    this.id = this.$route.params.id
    if (this.$route.query.lang == 'en') {
      this.tranLocaleSelect.text = 'English'
      this.tranLocaleSelect.value = 'en'
      this.fetchTerms(this.$route.query.lang)
    } else {
      this.tranLocaleSelect.text = 'Arabic'
      this.tranLocaleSelect.value = 'ar'
      this.fetchTerms(this.$route.query.lang)
    }
  },
  methods: {
    // submit add
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.data.locale = this.tranLocaleSelect.value
          this.data.term_id = this.termsSelect.value
          axios
            .post('items-guide/' + this.id + '/add-taxonomy', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    fetchTerms(lang) {
      axios
        .get('terms/' + lang)
        .then((result) => {
          const data = result.data.data
          for (let index in data) {
            if (data[index].translation != null) {
              this.terms.push({
                text: data[index].translation.name,
                value: data[index].id.toString(),
              })
            } else {
              this.terms.push({
                text: 'undefinded',
                value: data[index].id.toString(),
              })
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
 
</style>