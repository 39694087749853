var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Add Specify"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Locale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Locale","label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'locale',"disabled":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.localeOptions,"label":"text"},model:{value:(_vm.tranLocaleSelect),callback:function ($$v) {_vm.tranLocaleSelect=$$v},expression:"tranLocaleSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Term","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Term","label-for":"term","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":'term',"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.terms,"label":"text"},model:{value:(_vm.termsSelect),callback:function ($$v) {_vm.termsSelect=$$v},expression:"termsSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"taxonomy name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'name',"state":errors.length > 0 ? false : null},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"taxonomy description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":'texonomyDescription',"state":errors.length > 0 ? false : null},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)],1)])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,valIndex){return _c('li',{key:valIndex},[_vm._v(_vm._s(value))])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }